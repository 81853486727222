#root {
  min-height: 100vh;
  display: flex;

  .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding: 0 !important;
  }

  .container-fluid {
    min-height: 100%;

    .row {
      margin: 0 !important;

      > header, > section, > footer {
        width: 100%;
      }

      > section {
        padding: 15px;
        margin-bottom: 40px;
      }
    }
  }

  .error-message {
    font-size: 13px;
    text-align: center;
    color: red;
  }

  .bg-white {
    background-color: white;
  }

  h3 {
    color: saddlebrown;
    text-align: center;
    opacity: .8;
  }
  .highlighted {
    color: red;
    font-weight: bold;
    font-style: italic;
  }
}